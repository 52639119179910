<script>

import AuthPageCard from "@components/auth/AuthPageCard";
export default {
  components: {AuthPageCard},
  page: {
    title: 'Επαναφορά κωδικού',
    meta: [{ name: 'description', content: `Επαναφορά κωδικού στο athlisis` }],
  },
  data() {
    return {
        email: '',
      recoverHash: '',
      newPassword: '',
      repeatNewPassword: '',
      error: ''
    }
  },
  computed: {
  },
  mounted() {
    this.email = this.$route.query.email;
    this.recoverHash = this.$route.query.recover_hash;

    if(!this.email || !this.recoverHash){
      this.$router.push({name: 'forgot-password'})
    }
  },
  methods: {
    // ...authMethods,
    changePassword(){
      this.error = '';

      if(this.newPassword !== this.repeatNewPassword){
        this.error = 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι!';
        return;
      }

      this.$axios.post(`/users/forgot-password/recover`, { email: this.email, password: this.newPassword, recoverHash: this.recoverHash}).then(result => {
        this.$router.push({name: 'login'});
      }).catch(e =>{
        console.log(e);
      })

    }
  },
}
</script>

<template>
  <div id="recover-password">
    <auth-page-card>

      <template v-slot:default>
        <div class="mt-3 text-center">
          <h3>Επαναφορά κωδικού</h3>
          <p class="text-muted mt-2"> Εισάγετε έναν νέο κωδικό πρόσβασης για την πιστοποίησή σας στην εφαρμογή! </p>
          <b-form @submit.prevent="changePassword">
            <b-form-group id="password" label="Κωδικός πρόσβασης" label-for="password">
              <b-form-input id="new-password" v-model="newPassword" type="password" required placeholder="Εισάγετε έναν κωδικό πρόσβασης"></b-form-input>
            </b-form-group>
            <b-form-group id="new-password" label="Επανάληψη κωδικού πρόσβασης" label-for="password">
              <b-form-input id="repeat-new-password" v-model="repeatNewPassword" type="password" required placeholder="Επαναλάβετε τον κωδικό πρόσβασης"></b-form-input>
            </b-form-group>
            <b-form-group id="button-group" class="mt-4">
              <b-button type="submit" variant="primary" class="btn-block"><i class="icon-lock"></i> Αλλαγή κωδικού πρόσβασης</b-button>
            </b-form-group>
            <b-alert :show="error !== ''" variant="danger">{{error}}</b-alert>
          </b-form>
        </div>
      </template>
      <template v-slot:under-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-white-50">Έχετε ήδη έναν λογαριασμό;
              <router-link tag="a" to="/login" class="text-white ml-1"><b>Συνδεθείτε τώρα</b></router-link>
            </p>
          </div>
          <!-- end col -->
        </div>
      </template>
    </auth-page-card>
  </div>
</template>
